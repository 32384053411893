import { FC } from 'react';
import { useState } from 'react';
import Link from 'next/link';
import { IZhanr, IZhanrChild } from '@/interfaces/zhanr.interface';
import styles from './Sidebar.module.scss';

interface ISidebarProps {
  sidebarGenres?: IZhanr[] | IZhanrChild[];
  isSubGenresPage?: boolean;
}

const Sidebar: FC<ISidebarProps> = ({ sidebarGenres, isSubGenresPage }) => {
  const SIDEBAR_MIN_GENRES = 13;
  const SIDEBAR_MAX_GENRES = 50;

  const sideBarName = isSubGenresPage ? `Поджанры:` : `Популярные жанры:`;

  const [isSuperGenres, setIsSuperGenres] = useState(false);

  return (
    <div className={styles.sidebarWrap}>
      <div className={`titleH2 ${styles.sidebar__title}`}>{sideBarName}</div>
      <div className={isSuperGenres ? `${styles.sidebar} ${styles.sidebar_active}` : `${styles.sidebar}`} data-scrollbar="sidebar">
        <ul className={styles.sidebarList}>
          <li className={`${styles.sidebarList__item} ${styles.sidebarList__item_title}`}>
            <span>{sideBarName}</span>
          </li>

          {sidebarGenres && sidebarGenres.length && (
            <>
              {sidebarGenres.slice(0, SIDEBAR_MIN_GENRES).map((genre) => (
                <li key={genre.id} className={styles.sidebarList__item}>
                  <Link prefetch={false} href={`/zhanr/${genre.alias}/`}>
                    {genre.title}
                  </Link>
                </li>
              ))}

              {sidebarGenres.length > SIDEBAR_MIN_GENRES && (
                <li
                  onClick={() => setIsSuperGenres(!isSuperGenres)}
                  className={
                    isSuperGenres
                      ? `${styles.sidebarList__item} ${styles.sidebarList__item_toggle} ${styles.active}`
                      : `${styles.sidebarList__item} ${isSubGenresPage ? `${styles.sidebarList__item_toggle} ${styles.sidebarList__item_toggleSubGenres}` : styles.sidebarList__item_toggle}`
                  }
                >
                  Еще {isSubGenresPage ? 'поджанры' : 'жанры'}
                </li>
              )}
            </>
          )}

          <ul className={styles.sidebarList__hiddenGenres}>
            {sidebarGenres &&
              sidebarGenres.length > SIDEBAR_MIN_GENRES &&
              sidebarGenres.slice(SIDEBAR_MIN_GENRES, SIDEBAR_MAX_GENRES).map((genre) => (
                <li key={genre.id} className={styles.sidebarList__item}>
                  <Link prefetch={false} href={`/zhanr/${genre.alias}/`}>
                    {genre.title}
                  </Link>
                </li>
              ))}
          </ul>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
