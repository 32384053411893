import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styles from './AuthPopup.module.scss';
import FormBtn from '@/components/ui/form/form-btn/FormBtn';
import FormField from '@/components/ui/form/form-field/FormField';
import { useActions } from '@/hooks/useActions';
import Link from 'next/link';

interface IAuthPopup {
  isAuthActive: boolean;
  setAuthActive: (isAuthActive: boolean) => void;
}

const AuthPopup: FC<IAuthPopup> = ({ isAuthActive, setAuthActive }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { login } = useActions();

  const onSubmit: SubmitHandler<any> = (data) => {
    login(data);
    reset();
    setAuthActive(false);
  };

  const setOffAuthActiveHandle = () => {
    setAuthActive(false);
  };

  return (
    <div className={isAuthActive ? `${styles.authPopup} ${styles.authPopup_active}` : `${styles.authPopup}`}>
      <div className={styles.authPopup__bg} onClick={setOffAuthActiveHandle}></div>
      <div className={styles.authPopup__wrap}>
        <div className={styles.authPopup__btn} onClick={setOffAuthActiveHandle}></div>

        <div className={styles.authPopup__title}>Авторизация</div>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.authPopup__form}>
          <FormField
            {...register('email', {
              required: 'Пустое поле',
            })}
            error={errors.email}
            label="Email"
            name="email"
            placeholder=""
            id="email"
            type="text"
          />

          <FormField
            {...register('password', {
              required: 'Пустое поле',
            })}
            error={errors.password}
            label="Пароль"
            name="password"
            placeholder=""
            id="password"
            type="password"
          />

          <div className={styles.authPopup__controls}>
            <FormBtn type="submit" value="Войти" id="authorization" />
            <div className={styles.authPopup__controlsBtns}>
              <Link prefetch={false} className={`${styles.authPopup__controlsBtn} ${styles.authPopup__controlsBtn_register}`} href="/registration">
                Зарегистрироваться
              </Link>
              <Link prefetch={false} className={`${styles.authPopup__controlsBtn} ${styles.authPopup__controlsBtn_password}`} href="/forgot_pass">
                Восстановить пароль
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthPopup;
