import { FC } from 'react';
import styles from './SectionTitle.module.scss';

interface ISectionTitle {
  name: string;
  extraClassName?: string;
}

const SectionTitle: FC<ISectionTitle> = ({ name, extraClassName }) => {
  const getHeadingClassNames = () => (extraClassName ? `${styles.sectionHeading} ${styles[`${extraClassName}`]}` : styles.sectionHeading);

  return <div className={getHeadingClassNames()}>{name}</div>;
};

export default SectionTitle;
