import { FC, PropsWithChildren, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import Header from './header/Header';
import Footer from './footer/Footer';
import HeaderBanner from '../ui/banners/header-banner/HeaderBanner';
import BottomBanner from '../ui/banners/bottom-banner/BottomBanner';
import PopularCustomSlider from '@/components/ui/popular-custom-slider/PopularCustomSlider';
import Meta from '../seo/Meta';
import { IMeta } from '../../interfaces/meta.interface';
import localFont from 'next/font/local';
import AuthPopup from './auth-popup/AuthPopup';
import StickyBanner from '../ui/banners/sticky-banner/StickyBanner';
import Sidebar from './sidebar/Sidebar';
import { IZhanr, IZhanrChild } from '@/interfaces/zhanr.interface';
import { API_URL_CUSTOM_SLIDER_DEFAULT } from '@/helpers/variables';
import styles from './Layout.module.scss';

const fontArial = localFont({
  src: [
    {
      path: './../../fonts/arial.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: './../../fonts/arial-bold.woff',
      weight: '700',
      style: 'bold',
    },
  ],
});

interface ILayout extends IMeta {
  sidebarGenres?: IZhanr[];
  sidebarSubGenres?: IZhanrChild[];
}

const Layout: FC<PropsWithChildren<ILayout>> = ({ children, title, description, pageHref, canonicalHref, robotsNoFollow, cover, sidebarGenres, sidebarSubGenres, isDefaultPopularSlider = true }) => {
  const { error, data } = useQuery('Popular books slider data', () => fetch(`${API_URL_CUSTOM_SLIDER_DEFAULT}`).then((res) => res.json()), { enabled: isDefaultPopularSlider });

  const [isAuthActive, setAuthActive] = useState(false);

  //Закрыть попап авторизации
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setAuthActive(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <Meta title={title} description={description} pageHref={pageHref} cover={cover} canonicalHref={canonicalHref} robotsNoFollow={robotsNoFollow}>
      <div className={isAuthActive ? `${styles.pageWrapper} ${styles.pageWrapper_auth}` : `${styles.pageWrapper}`}>
        <HeaderBanner />

        <main className={styles.mainContainer} style={fontArial.style}>
          <Header isAuthActive={isAuthActive} setAuthActive={setAuthActive} />
          <div className={styles.mainContainer__wrap}>
            {sidebarGenres && (
              <div className={styles.mainContainer__sidebar}>
                <Sidebar sidebarGenres={sidebarSubGenres!?.length > 0 ? sidebarSubGenres : sidebarGenres} isSubGenresPage={sidebarSubGenres!?.length > 0} />
              </div>
            )}
            <div className={styles.mainContainer__content}>
              {children}
              {error || !isDefaultPopularSlider ? null : <PopularCustomSlider popularSlider={data} />}
            </div>
          </div>

          <Footer isAuthActive={isAuthActive} setAuthActive={setAuthActive} isDefaultPopularSlider={isDefaultPopularSlider} />
          <BottomBanner />
        </main>

        <StickyBanner />
        <AuthPopup isAuthActive={isAuthActive} setAuthActive={setAuthActive} />
      </div>
    </Meta>
  );
};

export default Layout;
