import { FC } from 'react';
import Script from 'next/script';
import styles from './FooterBanner.module.scss';

const FooterBanner: FC = () => {
  return (
    <div className={styles.footer_banners}>
      <div className={styles.footer_bannerDesktop}>
        {/* Yandex.RTB R-A-745625-4 */}
        <div id='yandex_rtb_R-A-745625-4'></div>
        <Script strategy='lazyOnload'>
          {`
          window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-745625-4',
          blockId: 'R-A-745625-4'})})
        `}
        </Script>
      </div>
      <div className={styles.footer_bannerMob}>
        {/* Yandex.RTB R-A-745625-3 */}
        <div id='yandex_rtb_R-A-745625-3'></div>
        <Script strategy='lazyOnload'>
          {`
          window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-745625-3',
          blockId: 'R-A-745625-3'})})
        `}
        </Script>
      </div>
    </div>
  );
};

export default FooterBanner;
