import { FC } from 'react';
import { useState } from 'react';
import Script from 'next/script';
import styles from './StickyBanner.module.scss';

const StickyBanner: FC = () => {
  const [isActive, setIsActive] = useState(true);

  const disableBannerHandle = () => {
    setIsActive(false);
  };

  return (
    <div className={`${styles.sticky_banner} ${!isActive ? `${styles.sticky_bannerDisable}` : ``}`} data-sticky-banner>
      <div onClick={disableBannerHandle} className={styles.sticky_banner_btn} data-sticky-banner-btn></div>

      <div className={styles.sticky_banner_wrap}>
        {/* <!-- Yandex.RTB R-A-745625-5 --> */}
        <div id="yandex_rtb_R-A-745625-5"></div>
        <Script strategy="lazyOnload">
          {`
          window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-745625-5',
          blockId: 'R-A-745625-5'})})
        `}
        </Script>
      </div>
    </div>
  );
};

export default StickyBanner;
