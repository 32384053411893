import { FC } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getAuthorFullName } from '@/helpers/helpers';
import { DEV_ABSOLUTE_URL, slickSliderConfig } from '@/helpers/variables';
import { IPopularBooksCustom } from '@/interfaces/popularBooksCustom.interface';
import { displayNoneImgOnError } from '@/helpers/helpers';
import { SELF_PUB_LINK } from '@/helpers/variables';
import styles from './SlickSlider.module.scss';

interface ISlickSliderPopular {
  books: IPopularBooksCustom[];
  isSelfPudAdvertBook?: boolean;
}

const SlickSliderPopular: FC<ISlickSliderPopular> = ({ books, isSelfPudAdvertBook }) => {
  return (
    <div className={styles.booksSlider}>
      <Slider {...slickSliderConfig} className={styles.booksSlider__wrap}>
        {isSelfPudAdvertBook && (
          <div>
            <div className={styles.booksSlider__item}>
              <div className={styles.booksSlider__cover}>
                <Link prefetch={false} href={SELF_PUB_LINK} target="_blank" title={`Стать автором`}>
                  <img loading="lazy" src="/banners/self-pub-slider.png" height="256" width="180" alt="slide" onError={displayNoneImgOnError} />
                </Link>
              </div>
              <div className={styles.booksSlider__name}>
                <Link prefetch={false} href={SELF_PUB_LINK} target="_blank" title={`Стать автором`}>
                  Будущий автор
                </Link>
              </div>
              <div className={styles.booksSlider__author}>
                <Link prefetch={false} href={SELF_PUB_LINK} target="_blank" title={`Стать автором`}>
                  Литрес Самиздат
                </Link>
              </div>
            </div>
          </div>
        )}

        {books!.map((book: IPopularBooksCustom) => {
          const { book_title, book_id, book_external_id } = book;

          const isCustomLink = book.book_custom_link !== null;

          const fullAuthorAlias = `/avtor/${book.author_alias}/`;
          const fullBookAlias = isCustomLink ? book.book_custom_link : `/avtor/${book.author_alias}/${book.book_alias}/`;
          const linkTarget = isCustomLink ? '_blanc' : '_self';
          const authorName = getAuthorFullName(book.author_first_name, book.author_middle_name, book.author_last_name);

          return (
            <div key={book_id}>
              <div className={styles.booksSlider__item}>
                <div className={styles.booksSlider__cover}>
                  {!isCustomLink ? (
                    <Link prefetch={false} target={linkTarget} href={fullBookAlias} title={`Скачать книгу ${book_title}`}>
                      <img loading="lazy" src={`${DEV_ABSOLUTE_URL}/covers_185/${book_external_id}.jpg`} height="256" width="180" alt="slide" onError={displayNoneImgOnError} />
                    </Link>
                  ) : (
                    <a target={linkTarget} href={fullBookAlias} title={`Скачать книгу ${book_title}`}>
                      <img loading="lazy" src={`${DEV_ABSOLUTE_URL}/covers_185/${book_external_id}.jpg`} height="256" width="180" alt="slide" onError={displayNoneImgOnError} />
                    </a>
                  )}
                </div>
                <div className={styles.booksSlider__name}>
                  {!isCustomLink ? (
                    <Link prefetch={false} target={linkTarget} href={fullBookAlias}>
                      {book_title}
                    </Link>
                  ) : (
                    <a target={linkTarget} href={fullBookAlias}>
                      {book_title}
                    </a>
                  )}
                </div>
                {!isCustomLink && (
                  <div className={styles.booksSlider__author}>
                    <Link prefetch={false} target={linkTarget} href={fullAuthorAlias}>
                      {authorName}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SlickSliderPopular;
