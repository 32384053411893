import { FC } from 'react';
import styles from './FormBtn.module.scss';

interface IInputBtn {
  id: string;
  type: string;
  value: string;
  captcha?: string | null;
  withCaptcha?: boolean;
}

const FormBtn: FC<IInputBtn> = ({ id, type, value, captcha, withCaptcha }) => {
  return (
    <div className={styles.inputWrap}>
      {withCaptcha ? <input type={type} value={value} id={id} className={captcha ? `${styles.inputBtn}` : `${styles.inputBtn} ${styles.inputBtn_disabled}`} /> : <input type={type} value={value} id={id} className={styles.inputBtn} />}
    </div>
  );
};

export default FormBtn;
