import { FC, PropsWithChildren } from 'react';
import Head from 'next/head';
import { IMeta } from '../../interfaces/meta.interface';

const Meta: FC<PropsWithChildren<IMeta>> = ({ title, description, pageHref, canonicalHref, cover, robotsNoFollow, children }) => {
  const findPageNumber = () => {
    const splitHref = pageHref?.split('/');

    //Client page number
    if (pageHref?.includes('page=') && !!splitHref?.length) {
      return splitHref[splitHref.length - 2];
    }

    //Server page number
    if (!!splitHref?.length) {
      const splitHrefLength = Number(splitHref?.length - 2);
      const pageNumber = splitHref[splitHrefLength];
      return Number(pageNumber) > 0 ? `page=${pageNumber}` : '';
    }

    //Plain pages
    return '';
  };

  const page = findPageNumber();
  const pageNumber = !!page.length ? Number(page.split('page=')[1]) : 0;
  const punctuation = ['.', '!', '?'];

  const titleWithoutPunctuation = punctuation.some((item) => title.includes(item)) ? title.slice(0, -1) : title;
  const fullTitle = `${pageNumber > 0 ? `${titleWithoutPunctuation}, страница ${pageNumber}` : `${title}`}`;
  const fullDescription = `${pageNumber > 0 ? `${description.slice(0, -1)}, страница ${pageNumber}.` : `${description}`}`;

  return (
    <>
      <Head>
        <script async type="text/javascript" src="/static/google-tag-manager.js"></script>
        <script type="text/javascript" src="/static/yandex-context-cb.js"></script>
        <script src="https://yandex.ru/ads/system/context.js" async />
        <title>{fullTitle}</title>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
        <meta name="description" content={fullDescription} />
        <meta name="og:title" content={fullTitle} />
        <meta name="og:description" content={fullDescription} />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}${canonicalHref}${page}`} />
        {!!robotsNoFollow ? <meta name="robots" content="noindex, nofollow" /> : ``}
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_SITE_URL}${canonicalHref}`} />
        {cover ? (
          <>
            <meta property="og:type" content="books.book" />
            <meta property="og:image" content={cover}></meta>
          </>
        ) : (
          <>
            <meta property="og:image" content="/kuchaknig_top_fon_ru.jpg"></meta>
          </>
        )}
        <link rel="icon" type="image/svg" href="/favicon/favicon.svg" />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
      </Head>
      {children}
      <div id="adCounters">
        <script async type="text/javascript" src="/static/gaq-counter.js"></script>
        <script async type="text/javascript" src="/static/ya-counter.js"></script>
        <noscript>
          <div>
            <img src="https://mc.yandex.ru/watch/17867158" style={{ position: 'absolute', left: '-9999px' }} />
          </div>
        </noscript>
        <noscript>
          <iframe loading="lazy" src="https://www.googletagmanager.com/ns.html?id=GTM-5DT5QMX" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
        </noscript>
      </div>
    </>
  );
};

export default Meta;
