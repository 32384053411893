import { FC } from 'react';
import Link from 'next/link';
import { SELF_PUB_LINK } from '@/helpers/variables';
import styles from './BottomBanner.module.scss';

const BottomBanner: FC = () => {
  return (
    <div className={styles.litresBottomBanner}>
      <Link prefetch={false} className={styles.litresBottomBanner__link} target="_blank" href={SELF_PUB_LINK}>
        <img width="1920" height="60" loading="lazy" className={styles.litresBottomBanner__img} src="/banners/self-pub-1200x90.png" alt="banner" />
        <img width="450" height="100" loading="lazy" className={`${styles.litresBottomBanner__img} ${styles.litresBottomBanner__img_mobile}`} src="/banners/self-pub-480x180.png" alt="banner" />
      </Link>
    </div>
  );
};

export default BottomBanner;
